import dotenv from 'dotenv'
dotenv.config()

export default class Configuration {
  static get CONFIG () {
    return {
      appBasePath: '$VUE_APP_BASE_PATH',
      app: '$VUE_APP_APP',
      env: '$VUE_APP_ENV',
      url: '$VUE_APP_URL',
      subDomain: '$VUE_APP_SUB_DOMAIN',
      gateway: '$VUE_APP_GATEWAY_SUFFIX',
      theme: '$VUE_APP_THEME',
      awsPublicUrl: '$VUE_APP_AWS_PUBLIC_URL',
      gtm: '$VUE_APP_GTM',
      gtm4: '$VUE_APP_GTM4',
      analyticsId: '%VUE_APP_ANALYTICS_ID',
      hotjarId: '$VUE_APP_HOTJAR_ID',
      lang: '$VUE_APP_LANG',
      cognitoPoolName: '$VUE_APP_COGNITO_POOL_NAME',
      cognitoRegion: '$VUE_APP_COGNITO_REGION',
      cognitoRedirectUrl: '$VUE_APP_COGNITO_REDIRECTION_URL',
      cognitoClientId: '$VUE_APP_COGNITO_CLIENT_ID',
      cognitoPoolId: '$VUE_APP_COGNITO_POOL_ID',
      aivo: '$VUE_APP_AIVO'
    }
  }

  static value (name) {
    if (!(name in this.CONFIG)) {
      //console.log(`Configuration: There is no key named "${name}"`)
      return
    }

    const value = this.CONFIG[name]

    if (!value) {
      //console.log(`Configuration: Value for "${name}" is not defined`)
      return
    }

    if (value.startsWith('$VUE_APP_')) {
      const envName = value.substr(1)
      // eslint-disable-next-line no-process-env
      const envValue = process.env[envName]
      if (envValue) {
        return envValue
      } else {
        //console.log(`Configuration: Environment variable "${envName}" is not defined`)
      }
    } else {
      return value
    }
  }
}
